import React from "react";
import {
  Routes,
  Route,
  Outlet,
  useNavigate,
} from "react-router-dom";

import { RequireLoggedInUser, useRealmApp } from "contexts/RealmApp";
import { useConfirmation } from "contexts/ConfirmationService";
import RealmApolloProvider from "contexts/RealmApolloProvider";
import SKUList from "pages/sku/list"
import SKUEdit from "pages/sku/edit"


const TopPage = () => {
  const showMessage = useConfirmation()
  return <div className="h-full flex justify-center items-center">トップ画面 - 上のメニューから選択してください</div>
}

const Router = () => {
  let app = useRealmApp();
  return (
    <Routes>
      <Route path="/" element={<TopPage />} />
      <Route path="/sku" element={
        <RequireLoggedInUser>
          <RealmApolloProvider>
          <Outlet />
          </RealmApolloProvider>
        </RequireLoggedInUser>
      }>
        <Route path="" element={<SKUList />} />
        <Route path="edit" element={<SKUEdit />} />
        <Route path="edit/:id" element={<SKUEdit />} />
      </Route>
    </Routes>
  );
};

export default Router;
