import React from "react";
import { ApolloClient, HttpLink, from, InMemoryCache, ApolloProvider, makeVar } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { useRealmApp } from "contexts/RealmApp";

const createRealmApolloClient = (appContext:RealmAppContextInterface) => {
    const httpLink = new HttpLink({
        uri: `https://eastasia.azure.realm.mongodb.com/api/client/v2.0/app/${appContext.app.id}/graphql`,
        fetch: async (uri, options) => {
            if (!appContext.currentUser) {
                throw new Error(`Must be logged in to use the GraphQL API`);
            }
            await appContext.currentUser.refreshCustomData();
            const headers = options?.headers ? new Headers(options.headers) : new Headers();
            if (options && !headers.has("Authorization")) {
                headers.set("Authorization", `Bearer ${appContext.currentUser.accessToken}`);
                options.headers = headers
            }
            return fetch(uri, options);
        },
    });

    const errorLink = onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors)
            graphQLErrors.forEach(({ message, locations, path }) =>
                console.log(
                    `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
                ),
            );

        if (networkError) console.log(`[Network error]: ${networkError}`);
    });
    const cache = new InMemoryCache()
    const connectToDevTools = true
    return new ApolloClient({ link: from([errorLink, httpLink]), cache, connectToDevTools });
};

export const reactiveStates = makeVar({});

export default function RealmApolloProvider({ children }:{ children:JSX.Element|JSX.Element[] }) {
    const appContext = useRealmApp();
    const [client, setClient] = React.useState(createRealmApolloClient(appContext));
    React.useEffect(() => {
        setClient(createRealmApolloClient(appContext));
        return (() => { client.clearStore(); })
    }, [appContext]);
    return <ApolloProvider client={client}>{children}</ApolloProvider>;
}