import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/solid';
import React, { ChangeEvent, FormEvent, MouseEvent, useState } from 'react'
import Loading from './Loading'

const PasswordInput = ({ name = "password", mode, value, onChange }: { name?: string; mode?: string, value: string, onChange: React.ChangeEventHandler<HTMLInputElement> }) => {
    const [show, setShow] = useState(false)
    return <div className="relative">
        <input
            id={name}
            name={name}
            type={show ? "text" : "password"}
            autoComplete="current-password"
            className={"appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-theme-500 focus:border-theme-500 focus:z-10 sm:text-sm" + (mode === "top" ? " rounded-t-md" : mode === "bottom" ? " rounded-b-md" : "")}
            placeholder="パスワード"
            value={value}
            onChange={onChange}
        />
        <div className="absolute inset-y-0 right-0 pr-1 flex items-center z-10 text-theme-800 cursor-pointer" onClick={() => setShow(!show)} >
            {show ? <EyeSlashIcon /> : <EyeIcon />}
        </div>
    </div>
}

export default function SignIn(param: { register: (email: string, password: string) => Promise<any>, signin: (email: string, password: string) => Promise<any>, providerSignin: (provider: string, href?:string) => Promise<any>, reset: (email: string) => Promise<any> }) {
    const { register, signin, providerSignin, reset } = param
    const [mode, setMode] = useState("login");
    const toggleMode = () => {
        setMode((oldMode) => (oldMode === "login" ? "register" : "login"));
        setMessage("")
        setError("")
    };
    const [data, setData] = useState({email:'', password:'', repeatPassword:''})
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setMessage("")
        setError("")
        setData({...data, [e.target.name]:e.target.value})
    }
    const [isProcessing, setIsProcessing] = useState(false);
    const [error, setError] = useState("");
    const [message, setMessage] = useState("");
    const [called, setCalled] = useState(false);
    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault()
        if (isProcessing) return null;
        setIsProcessing(true)
        if (mode === "login") {
            if (data.password.length < 8) {
                setError("パスワードは8文字以上必要です。");
            } else {
                const error = await signin(data.email || "", data.password);
                if (!error) return null
                setError(error);
            }
        } else {
            if (called) {
                alert("既に新規登録を受け付けています")
            } else if (data.email.length < 3) {
                setError('メールアドレスを入力してください。');
            } else if (data.password.length < 8) {
                setError("パスワードは8文字以上必要です。");
            } else if (data.password !== data.repeatPassword) {
                setError('再入力されたパスワードが一致しません。');
            } else {
                setCalled(true)
                const error = await register(data.email, data.password);
                if (error) {
                    setCalled(false)
                    setError(error);
                } else {
                    setMessage('入力されたメールアドレスに認証用リンクを送付しました。登録を完了するにはリンクをクリックしてください。')
                }
            }
        }
        setIsProcessing(false);
    }
    const handleLogin = (e: MouseEvent<HTMLButtonElement>) => {
        const provider = e.currentTarget.name
        if (provider) {
            providerSignin(provider, window.location.href)
        }
    }

    const handleResetPassword = async (e: MouseEvent) => {
        e.preventDefault()
        if (isProcessing) return null;
        setIsProcessing(true)
        if (data.email.length < 3) {
            setError("メールアドレスを入力してください。");
        } else {
            const error = await reset(data.email)
            if (error) {
                setError(error)
            } else {
                setMessage(`パスワード変更リンクを送信しました。`)
            }
        }
        setIsProcessing(false);
    }

    return <>{isProcessing && <Loading modal />}
        <div className="mx-auto h-full max-w-screen-sm w-full flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
            <div className="w-full space-y-8">
                <div>
                    <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">{mode === "login" ? "ログイン" : "新規登録"}</h2>
                </div>
                <form className="mt-6 space-y-6" onSubmit={handleSubmit} >
                    {error ? <div className="m-2 text-red-700">{error}</div> : <div className="m-2">{message}</div>}
                    <div className="rounded-md shadow-sm -space-y-px">
                        <div>
                            <label htmlFor="email" className="sr-only">
                                Email
                            </label>
                            <input
                                id="email"
                                name="email"
                                type={mode === "register" ? "email" : "text"}
                                autoComplete="email-address"
                                required
                                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-theme-500 focus:border-theme-500 focus:z-10 sm:text-sm"
                                placeholder="Email"
                                value={data.email}
                                onChange={handleChange}
                            />
                        </div>
                        <div>
                            <label htmlFor="password" className="sr-only">
                                パスワード
                            </label>
                            <PasswordInput mode={mode === "register" ? "" : "bottom"} value={data.password} onChange={handleChange} />
                        </div>
                        {mode === "register" &&
                            <div>
                                <label htmlFor="password" className="sr-only">
                                    パスワード（再入力）
                                </label>
                                <input
                                    id="repeatPassword"
                                    name="repeatPassword"
                                    type="password"
                                    autoComplete="current-password"
                                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-theme-500 focus:border-theme-500 focus:z-10 sm:text-sm"
                                    placeholder="パスワード（再入力）"
                                    value={data.repeatPassword}
                                    onChange={handleChange}
                                />
                            </div>}
                    </div>
                    <div>
                        <button
                            type="submit"
                            className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-theme-800 hover:bg-theme-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-theme-500"
                        >
                            {mode === "login" ? "ログイン" : "登録"}
                        </button>
                    </div>
                </form>
                <div className="flex justify-between text-theme-800 hover:text-theme-600">
                    {mode === "login" ? <div className="cursor-pointer" onClick={handleResetPassword}>パスワード再設定</div> : <div></div>}
                    <div
                        className="cursor-pointer"
                        onClick={(e) => {
                            e.preventDefault();
                            toggleMode();
                        }}
                    >
                        {mode === "login" ? "新規登録" : "ログイン"}
                    </div>
                </div>
                <div className="bg-white p-4 border flex flex-col gap-4">
                    <button
                        type="button"
                        name="google"
                        className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium text-white bg-theme-600 hover:bg-theme-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-theme-500"
                        onClick={handleLogin}
                    >
                        Googleでログイン
                    </button>
                    <button
                        type="button"
                        name="line"
                        className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium text-white bg-lime-600 hover:bg-lime-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lime-500"
                        onClick={handleLogin}
                    >
                        LINEでログイン
                    </button>
                    <button
                        type="button"
                        name="yahoo"
                        className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                        onClick={handleLogin}
                    >
                        Yahooでログイン
                    </button>
                </div>
            </div>
        </div>
    </>
}