import React, { ReactNode, useEffect, useState } from "react";
import * as Realm from "realm-web";
import { initializeApp } from "firebase/app";
import {
  getAuth,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  updatePassword,
  updateEmail as updateEmailFirebase,
  EmailAuthProvider,
  reauthenticateWithCredential,
  Auth,
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
  deleteUser as deleteUserFirebase,
  signInWithCustomToken,
} from "firebase/auth";

import SignIn from "components/SignIn";
import { handleException } from "contexts/errors";
import RequireVerify from "components/RequireVerify";
import { useLocation } from "react-router-dom";
import Loading from "components/Loading";

const firebaseConfig = {
  apiKey: "AIzaSyDJjVHdsMFnttp7DpklrkSuUajoJBqHyZ4",
  authDomain: "nl-dementia-pjt-dev.firebaseapp.com",
  projectId: "nl-dementia-pjt-dev",
  storageBucket: "nl-dementia-pjt-dev.appspot.com",
  messagingSenderId: "728033679732",
  appId: "1:728033679732:web:e3dc97591b888ac6c77421",
  measurementId: "G-1LTGLCX77P"
}
initializeApp(firebaseConfig);
const LINE_CLIENT_ID = '1661344349'
const YAHOO_CLIENT_ID = 'dj00aiZpPVBJSUJ6dGJMQ2ZkRyZzPWNvbnN1bWVyc2VjcmV0Jng9Yjc-'
const TOKEN_URL = 'https://eastasia.azure.data.mongodb-api.com/app/sampleapp-tfaif/endpoint/gettoken' // endpoint to return firebase custom jwt from code returned by auth endpoint of provider
const REDIRECT_URL = `${window.location.protocol}//${window.location.host}/auth` // !!! MUST MATCH URL INCLUDING PATH SET IN LINE or YAHOO

const getLineAuthUrl = (id:string) => {
  const state = 'line_' + Math.floor(Math.random() * Date.now()).toString(36);
  window.localStorage.setItem(`${id}:stateForAuth`, state);
  return `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${LINE_CLIENT_ID}&redirect_uri=${encodeURIComponent(REDIRECT_URL)}&state=${state}&scope=profile%20openid&nonce=09876xyz`
}
const getYahooAuthUrl = (id:string) => {
  const state = 'yahoo_' + Math.floor(Math.random() * Date.now()).toString(36);
  window.localStorage.setItem(`${id}:stateForAuth`, state);
  return `https://auth.login.yahoo.co.jp/yconnect/v2/authorization?response_type=code&client_id=${YAHOO_CLIENT_ID}&redirect_uri=${encodeURIComponent(REDIRECT_URL)}&state=${state}&scope=profile%20openid&nonce=09876xyz&prompt=login`
}

const auth = getAuth();
const providers:KV = {google:new GoogleAuthProvider(), facebook:new FacebookAuthProvider()}

const RealmAppContext = React.createContext<RealmAppContextInterface>(
  {} as RealmAppContextInterface
);

export const useRealmApp = () => {
  const appContext = React.useContext(RealmAppContext);
  if (!appContext) {
    throw new Error(
      `You must call useRealmApp() inside of a <RealmAppProvider />`
    );
  }
  return appContext;
};

export const sendVerification = async () => {
  try {
    if (!auth.currentUser) return "認証サーバーから認証情報を取得できません。時間を置いて再度操作するか、一旦ログアウトしてください。";
    await sendEmailVerification(auth.currentUser);
    return null;
  } catch (e) {
    return handleException(e);
  }
};
export const update = async (currentPassword:string, password: string) => {
  try {
    if (!auth.currentUser) return "認証サーバーから認証情報を取得できません。時間を置いて再度操作するか、一旦ログアウトしてください。";
    const credential = EmailAuthProvider.credential(auth.currentUser.email || "", currentPassword)
    await reauthenticateWithCredential(auth.currentUser, credential)
    await updatePassword(auth.currentUser, password);
    return null;
  } catch (e) {
    return handleException(e);
  }
};
export const updateEmail = async (currentPassword:string, email: string) => {
  try {
    if (!auth.currentUser) return "認証サーバーから認証情報を取得できません。時間を置いて再度操作するか、一旦ログアウトしてください。";
    const credential = EmailAuthProvider.credential(auth.currentUser.email || "", currentPassword)
    const userCredential = await reauthenticateWithCredential(auth.currentUser, credential)
    await updateEmailFirebase(auth.currentUser, email);
    if (userCredential?.user) await sendEmailVerification(userCredential.user)
    return null;
  } catch (e) {
    return handleException(e);
  }
};
export const reset = async (email: string) => {
  try {
    await sendPasswordResetEmail(auth, email);
    return null;
  } catch (e:any) {
    return handleException(e);
  }
};
export const deleteCurrentUser = async (app:Realm.App) => {
  try {
    if (!auth.currentUser || !app.currentUser) return "認証サーバーから認証情報を取得できません。時間を置いて再度操作するか、一旦ログアウトしてください。";
    if (!window.confirm("アカウントを削除します。この操作は取り消せません。よろしいですか？")) return null;
    await app.currentUser?.functions.deleteAppUser()
    await app.currentUser.logOut();
    await deleteUserFirebase(auth.currentUser);
    await auth.signOut()
    return null;
  } catch (e) {
    console.log(e)
    return handleException(e);
  }
};

export const RequireLoggedInUser = ({
  children,
}: {
  children: JSX.Element;
}): JSX.Element => {
  const realmAppContext = useRealmApp();
  const [processing, setProcessing] = useState(false);

  const signin = async (email: string, password: string) => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
      const token = (await auth.currentUser?.getIdToken()) || "";
      await realmAppContext.logIn(Realm.Credentials.jwt(token));
    } catch (e) {
      return handleException(e);
    }
  };
  const providerSignin = async (provider:string, href?:string) => {
    switch (provider) {
      case 'line':
        window.open(getLineAuthUrl(realmAppContext.app.id), '_blank')?.focus()
        break;
      case 'yahoo':
        window.open(getYahooAuthUrl(realmAppContext.app.id), '_blank')?.focus();
        break;
      default:
        try {
          await signInWithPopup(auth, providers[provider])
          const token = (await auth.currentUser?.getIdToken()) || "";
          await realmAppContext.logIn(Realm.Credentials.jwt(token));
        } catch (e) {
          return (e as Error)?.message;
        };
    }
  }
  const refresh = async () => {
    try {
      const token = (await auth.currentUser?.getIdToken()) || "";
      await realmAppContext.currentUser?.logOut();
      await realmAppContext.logIn(Realm.Credentials.jwt(token));
    } catch (e) {
      return handleException(e);
    }
  };
  const register = async (email: string, password: string) => {
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      if (userCredential?.user) await sendEmailVerification(userCredential.user)
      return null;
    } catch (e) {
      return handleException(e);
    }
  };

  const handleStorageEventUsingFirebase = async (e:StorageEvent) => {
    if (e.key === `${realmAppContext.app.id}:customTokenForFirebase` && e.newValue) {
        setProcessing(true)
        window.localStorage.removeItem(`${realmAppContext.app.id}-refreshFlag`);
        await signInWithCustomToken(auth, e.newValue)
        const tokenFromFirebase = (await auth.currentUser?.getIdToken()) || "";
        await realmAppContext.logIn(Realm.Credentials.jwt(tokenFromFirebase));
        setProcessing(false)
    }
  }
  const handleStorageEvent = async (e:StorageEvent) => {
    if (e.key === `${realmAppContext.app.id}:customTokenForFirebase` && e.newValue) {
        setProcessing(true)
        window.localStorage.removeItem(`${realmAppContext.app.id}-refreshFlag`);
        await realmAppContext.logIn(Realm.Credentials.function(JSON.parse(e.newValue)));
        setProcessing(false)
    }
  }

  useEffect(() => {
    window.addEventListener('storage', handleStorageEvent);
    return () => window.removeEventListener('storage', handleStorageEvent)
  }, []);
  return realmAppContext.currentUser ? (
    (realmAppContext.currentUser?.profile?.userId as string)?.startsWith('line:') || realmAppContext.currentUser?.providerType === "custom-function" || realmAppContext.currentUser?.profile?.emailVerified ? (
      children
    ) : (
      <RequireVerify send={sendVerification} refresh={refresh} logout={realmAppContext.logOut} />
    )
  ) : (
    processing ? <Loading /> : <SignIn signin={signin} providerSignin={providerSignin} register={register} reset={reset} />
  );
};

export const AuthPage = () => {
  const realmAppContext = useRealmApp();
  const searchParams = new URLSearchParams(window.location.search);
  const [code, state] = [searchParams.get('code'), searchParams.get('state')];
  const [error, setError] = useState(searchParams.get('error'))
  const stateFromStorage = window.localStorage.getItem(`${realmAppContext.app.id}:stateForAuth`,);

  // Using Firebase
  // useEffect(() => {
  //   const getToken = async () => {
  //     if (state && state === stateFromStorage && code && !error) {
  //       //-d 'redirect_uri=http://localhost:3000?path=%2Fsku' \ when setting url using URLSearchParams, do not encode url including parameter
  //       const responseToken = await fetch(TOKEN_URL, {method: 'POST', headers:{'Content-Type': 'application/json'}, body:JSON.stringify({provider:state.split('_')[0], code:code, redirect_uri:`${REDIRECT_URL}`})})
  //       if (responseToken && responseToken?.status === 201) {
  //         const data = await responseToken.json()
  //         window.localStorage.setItem(`${realmAppContext.app.id}:customTokenForFirebase`, data.token);
  //         window.localStorage.removeItem(`${realmAppContext.app.id}:stateForAuth`);
  //         window.opener = null;
  //         window.open("", "_self");
  //         window.close();
  //       } else {
  //         setError('true')
  //       }
  //     }
  //   }
  //   getToken()
  // }, [])
  
  // Using custom function
  useEffect(() => {
    const getToken = async () => {
      if (state && state === stateFromStorage && code && !error) {
        //-d 'redirect_uri=http://localhost:3000?path=%2Fsku' \ when setting url using URLSearchParams, do not encode url including parameter
         window.localStorage.setItem(`${realmAppContext.app.id}:customTokenForFirebase`, JSON.stringify({provider:state.split('_')[0], code:code, redirect_uri:`${REDIRECT_URL}`}));
         window.localStorage.removeItem(`${realmAppContext.app.id}:stateForAuth`);
         window.opener = null;
         window.open("", "_self");
         window.close();
      } else {
        setError('true')
      }
    }
    getToken()
  }, [])
  if (state !== stateFromStorage || !code || error) return <div className="h-full flex justify-center items-center text-red-700">ログインエラーが発生しました</div>
  return <div className="h-full flex justify-center items-center">ログイン処理中・・・</div>
}

export const RealmAppProvider = ({
  appId,
  children,
}: {
  appId: string;
  children: ReactNode;
}) => {
  const firstRun = React.useRef<boolean>(true);
  const [app, setApp] = React.useState<Realm.App>(new Realm.App(appId));
  useEffect(() => {
    if (firstRun.current) {
      firstRun.current = false;
      return;
    }
    setApp(new Realm.App(appId));
  }, [appId]);
  const [currentUser, setCurrentUser] = React.useState(app.currentUser);
  const logIn = async (credentials: Realm.Credentials) => {
    await app.logIn(credentials);
    setCurrentUser(app.currentUser);
  }
  const logOut = async () => {
    await app.currentUser?.logOut();
    await auth.signOut();
    setCurrentUser(app.currentUser);
  }
  const deleteUser = async () => {
    await deleteCurrentUser(app)
    setCurrentUser(app.currentUser);
  }

  const realmAppContext = { app, auth, currentUser, logIn, logOut, deleteUser };

  return (
    <RealmAppContext.Provider value={realmAppContext}>
      {children}
    </RealmAppContext.Provider>
  );
};
