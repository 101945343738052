import React, { ChangeEvent, MouseEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import { gql, useMutation, useQuery, useReactiveVar } from "@apollo/client"
import { BackspaceIcon, MagnifyingGlassIcon, PlusIcon, TrashIcon } from "@heroicons/react/24/solid"

import Loading from "components/Loading";
import { formatCurrency, formatNumber } from "contexts/style";
import { reactiveStates } from "contexts/RealmApolloProvider";
import { useConfirmation } from "contexts/ConfirmationService";

export const skuColumns = [
    { text: "SKUコード", field: "_id" },
    { text: "SKU名", field: "skuname" },
    { text: "商品名", field: "name" },
    { text: "金額", field: "price", type: "currency" },
    { text: "在庫", field: "quantity", type: "number" },
    { text: "タグ", field: "tag", type: "array" },
    { text: "表示", field: "display", type: "boolean" }
]

export const fragment =  gql`
    fragment SkuFields on Sku {
        _id
        skuname
        name
        price
        quantity
        tag
        display
    }
`

const query = gql`
    ${fragment}
    query($query: SkuQueryInput!) {
        skus(query: $query, sortBy: _ID_ASC) {
            ...SkuFields
        }
    }
`

const deleteMutation = gql`
    ${fragment}
    mutation($_id: String!) {
        deletedData: deleteOneSku(query: { _id: $_id }) {
            ...SkuFields
        }
    }
`

const DeleteButton = ({ id, onClick }: { id:string, onClick?: (e:MouseEvent<HTMLButtonElement>) => void }) =>
    <button
        type="button"
        data-id={id}
        onClick={onClick}
        className="btn btn-square btn-sm btn-ghost"
    >
        <TrashIcon className="w-6 h-6" />
    </button>

const FilterInput = ({ label, name, type = "text", value, setValue }: { label: string; name: string; type?: string; value: KV; setValue: React.Dispatch<React.SetStateAction<KV>> }) => {
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setValue({ ...value, [e.currentTarget.name]: e.currentTarget.value })
    }
    return <>
        <label htmlFor={name} className="label">{label}</label>
        <input
            type={type}
            name={name}
            className="input input-bordered"
            value={value[name]}
            onChange={handleChange}
        />
    </>
}


const Cell = ({ column, value }: { column: KV, value: any }) => {
    switch (column.type) {
        case "currency": return <div className="text-right">{formatCurrency(value)}</div>
        case "number": return <div className="text-right">{formatNumber(value)}</div>
        case "boolean": return <div className="text-center">{value ? '◯' : ''}</div>
        case "array": return <>{value ? value.join(", ") : ""}</>
        default: return <>{value}</>
    }
}

const Filter = () => {
    const states: KV = useReactiveVar(reactiveStates)
    const [data, setData] = useState<KV>({ _id: "", skuname: "", name: "", tag_in: "", ...states.sku })
    const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
        switch (e.currentTarget.name) {
            case "search":
                let cleanData = { ...data } // Be careful this is shallow copy
                for (const key in cleanData) {
                    if (!cleanData[key]) {
                        delete cleanData[key];
                    }
                }
                reactiveStates({ ...reactiveStates, sku: cleanData })
                break
            case "clear":
                setData({ _id: "", skuname: "", name: "", tag_in: "" })
                break
            default:
        }
    }
    return <div className="navbar bg-secondary text-secondary-content gap-4">
            <FilterInput label="SKUコード" name="_id" value={data} setValue={setData} />
            <FilterInput label="SKU名" name="skuname" value={data} setValue={setData} />
            <FilterInput label="商品名" name="name" value={data} setValue={setData} />
            <FilterInput label="タグ" name="tag_in" value={data} setValue={setData} />
            <button className="btn btn-primary w-28" name="search" onClick={handleClick}><MagnifyingGlassIcon className="h-6 w-6"/> 検索</button>
            <button className="btn btn-primary w-28" name="clear" onClick={handleClick}><BackspaceIcon className="h-6 w-6" />クリア</button>
    </div>

}


export default function SKUList() {
    const navigate = useNavigate()
    const showMessage = useConfirmation()
    const states: KV = useReactiveVar(reactiveStates)
    const { loading, data, error } = useQuery(query, { variables: { query: states.sku || {} } })
    const [deleteData, { loading:deleteLoading, data:deletedData, error:deleteError }] = useMutation(deleteMutation, { 
        update: (cache, { data: { deletedData } }) => { //remove data from cache
            const normalizedId = cache.identify(deletedData);
            cache.evict({ id: normalizedId });
            cache.gc();
        }
    })
    const handleClick = (e: MouseEvent<HTMLElement>) => {
        navigate(`edit/${e.currentTarget.dataset.id}`)
    }
    const handleDelete = async (e:MouseEvent<HTMLButtonElement>) => {
        const id = e.currentTarget.dataset.id
        const result = await showMessage(`SKUコード：${id}　のレコードを削除しますか？`, {confirm:true})
        if (!result) return
        await deleteData({ variables: { _id: id }})
    }
    if (loading) return <Loading />
    if (error) return <div className="h-full flex flex-col justify-center items-center text-red-600">
        <span>エラーが発生しました</span>
        {error.graphQLErrors.map(({ message }, i) => <span key={i}>{message}</span>)}
        {<span>{error.networkError?.message}</span>}
    </div>
    return <>
        <div className="absolute left-0 top-0 w-full h-full z-10 pointer-events-none">
            <button
        type="button"
        onClick={() => navigate('edit')}
        className="fixed right-4 bottom-10 btn btn-primary btn-circle pointer-events-auto"
    >
         <PlusIcon className="w-8 h-8"/>
    </button>
        </div>
        <div className="relative h-full grid grid-rows-list">
            <Filter />
            <div></div>
            <div className="px-2 pb-2 h-full overflow-scroll flex flex-col z-0">
                <div className="m-4 p-px grid grid-cols-[repeat(6,minmax(0,1fr))_minmax(0,max-content)_minmax(0,max-content)] gap-px bg-gray-200">
                    {[...skuColumns, { text: "" }].map(column => <div key={column.text} className={`p-2 text-center bg-secondary text-secondary-content`}>{column.text}</div>)}
                    {data.skus.map((sku: KV) => <React.Fragment key={sku._id}>
                        {skuColumns.map(column => <div key={sku._id + column.field} data-id={sku._id} className="p-2 bg-base-100 cursor-pointer" onClick={handleClick}><Cell column={column} value={sku[column.field]} /></div>)}
                        <div className="px-2 flex items-center bg-base-100"><DeleteButton id={sku._id} onClick={handleDelete} /></div>
                    </React.Fragment>)}
                </div>
            </div>
        </div>
    </>
}
