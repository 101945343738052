import { useRealmApp } from "contexts/RealmApp";
import React from "react";
import { Link } from "react-router-dom";

export default function Header() {
    const app = useRealmApp()
    return <header className="navbar bg-primary text-primary-content">
        <div className="navbar-start text-lg">Sample App</div>
        <div className="navbar-center">
            <Link to="/" className="btn btn-ghost">Home</Link>
            <Link to="/sku" className="btn btn-ghost">商品管理(SKU)</Link>
        </div>
        <div className="navbar-end">{app.currentUser && <><button className="btn btn-ghost" onClick={() => app.logOut()}>ログアウト</button><button  className="btn btn-ghost" onClick={() => app.deleteUser()}>アカウント削除</button></>}</div>
    </header>
}