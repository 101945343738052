import React, { useEffect, useState } from 'react'
import { BrowserRouter } from 'react-router-dom';

import { AuthPage, RealmAppProvider } from 'contexts/RealmApp';
import Router from 'components/Router'
import Header from 'components/Header';
import { ConfirmationServiceProvider } from 'contexts/ConfirmationService';
const APP_ID = 'dementia-protection-ffqtm'

export default function App() {
    const [mobile, setMobile] = useState<boolean | null>(null)
    useEffect(() => {
        const handleResize = () => setMobile(!Boolean(document.getElementById('breakpoint-sm')?.offsetParent))
        handleResize()
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [])
    return window.location.pathname === '/auth' ? 
        <RealmAppProvider appId={APP_ID}>
            <AuthPage />
        </RealmAppProvider>
     : <RealmAppProvider appId={APP_ID}>
        <ConfirmationServiceProvider>
            <div id="breakpoint-sm" className="hidden sm:block w-0 h-0"></div>
            <div className="App grid h-full grid-rows-frame">
                <BrowserRouter>
                    <Header />
                    <div className='relative h-full overflow-y-scroll'>
                        <Router />
                    </div>
                    <footer className="footer footer-center bg-primary text-primary-content">SAMPLE</footer>
                </BrowserRouter>
            </div>
        </ConfirmationServiceProvider>
    </RealmAppProvider>;
}
