import { gql, useLazyQuery, useMutation } from "@apollo/client"
import Loading from "components/Loading"
import { useConfirmation } from "contexts/ConfirmationService"
import React, { ChangeEvent, FormEvent, MouseEvent, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { fragment, skuColumns } from "./list"

const Input = ({ column, value, onChange, readOnly }: { column: KV, value: any, onChange: (e: ChangeEvent<HTMLInputElement>) => void, readOnly?: boolean }) => <div>
    <label htmlFor={column.field} className="label">{column.text}</label>
    <input type={column.type === "boolean" ? "checkbox" : "text"} className={column.type === "boolean" ? "checkbox input-bordered" : "input input-bordered"} name={column.field} value={value} checked={value} onChange={onChange} readOnly={readOnly} />
</div>

const FormList = ({ label, name, values, setValues }: { label: string; name: string, values: KV, setValues: React.Dispatch<React.SetStateAction<KV>> }) => {
    const showMessage = useConfirmation()
    const [addingValue, setAddingValue] = useState("")
    const [selectedValue, setSelectedValue] = useState<string[]>([])
    const items: string[] = (Array.isArray(values[name]) && values[name].length > 0) ? [...values[name]] : []
    const addItem = async (e: MouseEvent<HTMLButtonElement>) => {
        if (items.includes(addingValue)) {
            await showMessage(`この名称の${label}はすでに存在します`, { error: true })
            return null
        }
        setValues({ ...values, [name]: [...items, addingValue] })
        setAddingValue("")
    }
    const deleteItem = (e: MouseEvent<HTMLButtonElement>) => {
        setValues({ ...values, [name]: items.filter(v => !selectedValue.includes(v)) })
        setSelectedValue([])
    }
    return <div className="grid grid-cols-[max-content_max-content] auto-rows-min gap-1">
        <span className="p-1 text-sm col-span-2">{label}</span>
        <input
            className="input input-bordered"
            type="text"
            placeholder={`追加する${label}`}
            value={addingValue}
            onChange={e => setAddingValue(e.currentTarget.value)}
        />
        <button type="button" className="btn btn-sm btn-primary" onClick={addItem}>追加</button>
        <select className="select select-bordered" size={3} multiple value={selectedValue} onChange={e => setSelectedValue(Array.from(e.target.selectedOptions).map(o => o.value))}>
            {items.map(item => <option key={item} value={item}>{item}</option>)}
        </select>
        <button type="button" className="btn btn-sm btn-primary" onClick={deleteItem}>削除</button>
    </div>

}


const query = gql`
    ${fragment}
    query($query: SkuQueryInput!) {
        sku(query: $query) {
            ...SkuFields
        }
    }
`

const mutation = gql`
    ${fragment}
    mutation($query: SkuQueryInput, $data: SkuInsertInput!) {
        upsertedData: upsertOneSku(query: $query, data: $data) {
            ...SkuFields
        }
    }
`

export default function Edit() {
    const { id } = useParams()
    const navigate = useNavigate()
    const showMessage = useConfirmation()
    const [data, setData] = useState<KV>({})
    const [getData, { loading, data: queryData, error }] = useLazyQuery(query)
    const [upsertOneSku, { loading: upserting, data: upsertedData, error: upsertError }] = useMutation(mutation)
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setData({ ...data, [e.currentTarget.name]: e.currentTarget.type === "checkbox" ? e.currentTarget.checked : e.currentTarget.value })
    }
    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault()
        if (!data._id) {
            await showMessage("SKUコードは必須項目です", { error: true })
            return
        }
        const { __typename, _id, ...upsertingData } = data
        await upsertOneSku({
            variables: { query: { _id: _id }, data: upsertingData },
            ...(id ? {} : { // update cache if inserted.  if updated, cache is automatically updated 
                update: (cache, { data: { upsertedData } }) => cache.modify({
                    fields: {
                        skus: (existingData = []) => [
                            ...existingData,
                            cache.writeFragment({
                                data: upsertedData,
                                fragment: fragment,
                            }),
                        ],
                    },
                }),
            })
        })
        await showMessage(`SKUコード：${_id}　が${id ? "更新" : "作成"}されました`)
    }
    useEffect(() => {
        const get = async () => await getData({ variables: { query: { _id: id } } })
        if (id) get()
    }, [])

    useEffect(() => {
        if (queryData?.sku) setData(queryData.sku)
    }, [queryData])

    if (error) return <div className="h-full flex flex-col justify-center items-center text-error">
        <span>データ取得時にエラーが発生しました</span>
        {error.graphQLErrors.map(({ message }, i) => <span key={i}>{message}</span>)}
        {<span>{error.networkError?.message}</span>}
        <button type="button" className="btn btn-primary" onClick={() => navigate(-1)}>戻る</button>
    </div>

    return <>
        {(loading || upserting) && <Loading modal />}
        <form onSubmit={handleSubmit}>
            <div className="p-4 text-lg text-center text-secondary-content bg-secondary ">{`商品情報の${id ? "編集" : "新規作成"}`}</div>
            {upsertError && <div className="m-2 p-1 flex flex-col justify-center items-center text-error bg-red-200 rounded-md">
                <span>データ保存時にエラーが発生しました</span>
                {upsertError.graphQLErrors.map(({ message }, i) => <span key={i}>{message}</span>)}
                {<span>{upsertError.networkError?.message}</span>}
            </div>}
            <div className="mx-auto py-4 w-96 flex flex-col items-left gap-4">{skuColumns.map(column =>
                column.type === "array" ?
                    <FormList key={column.field} label={column.text} name={column.field} values={data} setValues={setData} />
                    : <Input key={column.field} column={column} value={data?.[column.field] || ""} onChange={handleChange} readOnly={Boolean(id && column.field === "_id")} />)}</div>
            <div className="bg-secondary flex p-2 justify-end gap-2"><button type="submit" className="btn btn-primary w-28">確定</button><button type="button" className="btn btn-primary w-28" onClick={() => navigate(-1)}>戻る</button></div>
        </form>
    </>
}