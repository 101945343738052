import React, { createContext, useContext, useRef, useState } from "react";
import MessageBox from "components/MessageBox";

/**
 * Disable tab select for form element and href
 *
 * @param {boolean} disable
 */
const disableTabIndex = (disable: boolean) => {
    var universe = document.querySelectorAll('input, button, select, textarea, a[href]')
    var list: HTMLElement[] = Array.prototype.filter.call(universe, item => (item instanceof HTMLElement) && !item.dataset?.focustab)
    if (disable) for (const item of list) { 
        item.dataset.tabIndex = item.tabIndex === undefined ? undefined : String(item.tabIndex)
        item.tabIndex = -1
    }
    else for (const item of list) { if (item.dataset.tabIndex === undefined) item.removeAttribute("tabindex"); else item.tabIndex = parseInt(item.dataset.tabIndex) }
}

const ConfirmationServiceContext = createContext<(message: string, options?: { error?: boolean, confirm?: boolean }) => Promise<unknown>>(() => new Promise<boolean>(resolve => resolve(false)))

export const useConfirmation = () => useContext(ConfirmationServiceContext);

export const ConfirmationServiceProvider = ({ children }: { children: JSX.Element[] }) => {
    const [message, setMessage] = useState("")
    const [options, setOptions] = useState<{ error?: boolean, confirm?: boolean }>()
    const awaitingPromiseRef = useRef<{ resolve: (value: unknown) => void, reject: (value: unknown) => void }>()
    const showMessage = (message: string, options?: { error?: boolean, confirm?: boolean }) => {
        return new Promise((resolve, reject) => {
            try {
                awaitingPromiseRef.current = { resolve, reject }
                setMessage(message)
                setOptions(options);
                disableTabIndex(true)
            } catch (e) { // Use browser default alert instead
                handleClose()
                let returnValue = true
                if (!options?.confirm) alert(message)
                else returnValue = window.confirm(message)
                resolve(returnValue)
            }
        })
    };

    const handleClose = () => {
        setMessage("")
        setOptions({})
        disableTabIndex(false)
    };

    return (
        <>
            <ConfirmationServiceContext.Provider
                value={showMessage}
                children={children}
            />

            {message && <MessageBox
                message={message}
                options={options}
                resolve={awaitingPromiseRef.current!.resolve}
                cleanup={handleClose}
            />}
        </>
    );
};
