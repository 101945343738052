import React from "react";
import Loading from "./Loading";

export default function RequireVerify({send, refresh, logout}:{send:() => Promise<string | null>, refresh:() => Promise<string | undefined>, logout:() => Promise<void>}) {
  const [isProcessing, setIsProcessing] = React.useState(false);
    const [error, setError] = React.useState("メールを認証してから再度ログインしてください。");
    const [message, setMessage] = React.useState("");
    const handleSend = async (e:any) => {
        setIsProcessing(true)
        setError("")
        setMessage("")
        const error = await send();
        if (error) setError(error)
        else setMessage("認証メールを再送しました。")
        setIsProcessing(false);
    }
    return (<>{isProcessing ? <Loading /> :
      <div className="h-full w-full max-w-screen-sm flex flex-col items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
        {error ? <div className="mx-2 my-6 text-red-700">{error}</div> : <div className="mx-2 my-6">{message}</div>}
        <button
            className="group relative w-36 flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-theme-800 hover:bg-theme-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-theme-500"
            onClick={logout}
        >
          <span className="flex items-center pl-3">
          </span>
          ログアウト
        </button>
        <div
          className="mx-2 my-6 text-theme-800 hover:text-theme-600　cursor-pointer"
          onClick={handleSend}
        >
          認証メール再送
        </div>
      </div>
    }</>);
}
